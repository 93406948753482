/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CloseOutlined } from '@ant-design/icons';

import { ConfigRevision } from '@/client';
import { RelativeTime } from '@/components/RelativeTime';
import { Avatar } from '@/primitives/Avatar';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { MimicSyntaxHighlighter } from '@/v1/components/MimicSyntaxHighlighter';

export type ConfigurationDetailsRevisionCodeProps = {
  configRevision: ConfigRevision | undefined;
  onClose: (isOpen: boolean) => void;
};

export function ConfigurationDetailsRevisionCode({ configRevision, onClose }: ConfigurationDetailsRevisionCodeProps) {
  if (!configRevision) {
    return null;
  }

  return (
    <div className="revision-code" style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
      <Card padding="sm" gap="xs" style={{ paddingTop: 0 }}>
        <Flex align="center" justify="space-between" full>
          <Text type="default" size="md" mono>
            {configRevision.filename}
          </Text>
          <Button dataTestId="close-revision-code" type="text" onClick={() => onClose(false)}>
            <CloseOutlined />
          </Button>
        </Flex>
        <Text
          type="default"
          size="lg"
          strong
        >{`Revision #${configRevision.revisionNumber}: ${configRevision.description}`}</Text>
        <Flex align="center" gap="xs">
          <Avatar text={configRevision.createdBy.displayName[0]} size="xs" dataTestId="display-name" />
          <Text type="subtle" size="sm">
            {configRevision.createdBy.displayName},
          </Text>
          <Text type="subtle" size="sm">
            <RelativeTime date={configRevision!.createdAt} />
          </Text>
          <Text type="subtle" size="sm">
            |
          </Text>
          <Text type="subtle" size="sm" mono copyable>
            {configRevision.id}
          </Text>
        </Flex>
      </Card>
      <MimicSyntaxHighlighter source={configRevision.source} />
    </div>
  );
}
