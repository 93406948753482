import { NodeConfigFilterParamsSchema } from 'openapi-spec/api/routes/node-configs/schemas';

import { GetConfigsData, GetConfigsResponse, NodeConfig, NodeConfigFilters, NodeConfigSortableFields } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedNodeConfigsQueryParams = {
  params?: Omit<GetConfigsData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
  overRideParamFilters?: boolean;
};

/**
 * Hook for fetching paginated node configs with filtering, sorting and real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated node configs response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export const usePaginatedNodeConfigsQuery = ({
  params,
  type,
  overRideParamFilters = false,
}: UsePaginatedNodeConfigsQueryParams) => {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  return usePaginatedQuery<GetConfigsResponse, NodeConfig, NodeConfigFilters, NodeConfigSortableFields, GetConfigsData>(
    {
      apiCall: mgmtPlaneAPIClient.nodeConfigs.getConfigs.bind(mgmtPlaneAPIClient.nodeConfigs),
      filterTypes: zodSchemaToTypeMap(NodeConfigFilterParamsSchema.shape),
      overRideParamFilters,
      queryKey: 'nodeConfigs',
      realtimeEvents: [
        'node-config:created',
        'node-config:updated',
        'node-config-revision:created',
        'node-config:assigned',
        'node-config:applied',
      ],
      params: {
        tenantId: selectedTenantId!,
        ...params,
      },
      type,
    },
  );
};
