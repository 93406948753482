import { NodeHallmarkFilterParamsSchema } from 'openapi-spec/api/routes/node-hallmarks/schemas';

import {
  GetHallmarksByNodeData,
  GetHallmarksByNodeResponse,
  NodeHallmark,
  NodeHallmarkFilters,
  NodeHallmarkSortableFields,
} from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { usePaginatedQuery } from '@/hooks/query/usePaginatedQuery';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { zodSchemaToTypeMap } from '@/utils/filters';

export type UsePaginatedNodeHallmarksQueryParams = {
  params: Omit<GetHallmarksByNodeData, 'tenantId'>;
  type?: 'inMemory' | 'queryParams';
  overRideParamFilters?: boolean;
};

/**
 * Hook for fetching paginated node hallmarks with filtering, sorting and real-time updates.
 *
 * @param options Configuration object:
 *   - params: Optional query parameters (filters, pagination, sort)
 *   - type: Storage type - 'inMemory' or 'queryParams' for URL persistence
 *
 * @returns Object containing:
 *   - data: Paginated node hallmarks response
 *   - isLoading: Boolean for initial load state
 *   - isFetching: Boolean for any loading state
 *   - isError: Boolean for error state
 *   - error: Error object if request failed
 *   - updateQueryParams: Function to update filters, sort, and pagination
 *
 * Note: tenantId is automatically pulled from the management plane state store
 */
export const usePaginatedNodeHallmarksQuery = ({
  params,
  type,
  overRideParamFilters = false,
}: UsePaginatedNodeHallmarksQueryParams) => {
  const selectedTenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  return usePaginatedQuery<
    GetHallmarksByNodeResponse,
    NodeHallmark,
    NodeHallmarkFilters,
    NodeHallmarkSortableFields,
    GetHallmarksByNodeData
  >({
    apiCall: mgmtPlaneAPIClient.nodes.getHallmarksByNode.bind(mgmtPlaneAPIClient.nodes),
    filterTypes: zodSchemaToTypeMap(NodeHallmarkFilterParamsSchema.shape),
    overRideParamFilters,
    queryKey: 'node-hallmarks',
    realtimeEvents: ['hallmark:created'],
    params: {
      tenantId: selectedTenantId!,
      ...params,
    },
    type,
  });
};
