/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { InviteMemberRoute } from '@/routes/InviteMemberRoute';
import { MembersRoute } from '@/routes/MembersRoute';
import { NextRoutes } from '@/routes/NextRoutes';
import { NodeDetailsRoute } from '@/routes/NodeDetailsRoute';
import { TagsRoute } from '@/routes/TagsRoute';
import { UpdateMemberRoute } from '@/routes/UpdateMemberRoute';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { HTTPError } from '@/v1/components/HTTPError';
import { ActivityRoute } from '@/v1/routes/Activity/ActivityRoute';
import { CLIAuthRoute } from '@/v1/routes/CLIAuthRoute';
import { CLIDownloadRoute } from '@/v1/routes/CLIDownloadRoute';
import { CLISetupRoute } from '@/v1/routes/CLISetupRoute';
import { NodeConfigurationRevisionSourceCodeRoute } from '@/v1/routes/ConfigRevisionRoute';
import { ConfigureNodesJobWizardRoute } from '@/v1/routes/ConfigureNodesJobWizardRoute';
import { SwaggerUiRoute } from '@/v1/routes/Docs/SwaggerUiRoute';
import { JobDetailsRoute } from '@/v1/routes/JobDetailsRoute';
import { JobsRoute } from '@/v1/routes/JobsRoute';
import { LogInRoute } from '@/v1/routes/LogInRoute';
import { NodeConfigurationDetailsRoute } from '@/v1/routes/NodeConfigurationDetailsRoute';
import { NodeList } from '@/v1/routes/NodeList';
import { ConfigsRoute } from '@/v1/routes/Nodes/ConfigsRoute';
import { NodeListRoute } from '@/v1/routes/Nodes/NodeListRoute';
import { NodeViewRoute } from '@/v1/routes/Nodes/NodeViewRoute';
import { NotificationsRoute } from '@/v1/routes/Notifications/NotificationsRoute';
import { SystemInfo } from '@/v1/routes/SystemInfo';

import { CenterLayout } from './CenterLayout';
import { GuardedRoute } from './GuardedRoute';
import { MainLayout } from './MainLayout';
import './theme/tokens.css';
import { ClearHallmarksModal } from './pages/Hallmarks/ClearHallmarksModal';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function Error500() {
  Sentry.captureException(new Error('Error boundaries could not catch the error.'));
  return <HTTPError httpStatus="500" />;
}

export function AppRoutes() {
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const router = sentryCreateBrowserRouter([
    {
      path: '/callback',
      errorElement: <Error500 />,
      element: <Navigate to={`/tenants/${selectedTenantID}/activity`} />,
    },
    {
      path: '/',
      element: <MainLayout />,
      errorElement: <Error500 />,
      children: [
        {
          index: true,
          element: <Navigate to={`/tenants/${selectedTenantID}/activity`} replace />,
        },
        {
          path: '/n/tenants/:tenantId',
          element: <NextRoutes />,
          children: [
            {
              path: 'nodes/:nodeId',
              element: <NodeDetailsRoute />,
            },
          ],
        },
        {
          path: '/tenants/:tenantID/activity',
          element: <ActivityRoute />,
        },
        {
          path: '/tenants/:tenantID/job-configure-nodes',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <ConfigureNodesJobWizardRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/node-list/',
          element: <NodeList />,
        },
        {
          path: '/tenants/:tenantID/jobs/',
          element: <JobsRoute />,
        },
        {
          path: '/tenants/:tenantID/jobs/:jobId',
          element: <JobDetailsRoute />,
        },
        {
          path: '/tenants/:tenantID/nodes',
          element: <NodeListRoute />,
        },
        {
          path: '/tenants/:tenantID/nodes/:id',
          element: <NodeViewRoute />,
          children: [
            {
              path: 'clear-hallmarks',
              element: (
                <GuardedRoute requiredRole="superadmin">
                  <ClearHallmarksModal />
                </GuardedRoute>
              ),
            },
          ],
        },
        {
          path: '/tenants/:tenantID/node-configs',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <ConfigsRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/node-configs/:nodeConfigId',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <NodeConfigurationDetailsRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/node-configs/:nodeConfigId/revisions/:configRevisionNumber',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <NodeConfigurationRevisionSourceCodeRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/notifications',
          element: <NotificationsRoute />,
        },
        {
          element: <MembersRoute />,
          path: '/tenants/:tenantID/members',
          children: [
            {
              path: 'invite',
              element: (
                <GuardedRoute requiredRole="admin">
                  <InviteMemberRoute />
                </GuardedRoute>
              ),
            },
            {
              path: 'update/:userID',
              element: (
                <GuardedRoute requiredRole="admin">
                  <UpdateMemberRoute />
                </GuardedRoute>
              ),
            },
          ],
        },
        {
          path: '/tenants/:tenantID/tags',
          element: <TagsRoute />,
        },
        {
          path: '/tenants/:tenantID/mimic-cli/download',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <CLIDownloadRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/mimic-cli/authenticate',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <CLIAuthRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/tenants/:tenantID/docs/swagger',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <SwaggerUiRoute />
            </GuardedRoute>
          ),
        },
        {
          path: '/system-info',
          element: <SystemInfo />,
        },
        {
          path: '*',
          element: <HTTPError httpStatus="404" />,
        },
      ],
    },
    {
      path: '/',
      element: <CenterLayout />,
      children: [
        {
          path: '/login',
          element: <LogInRoute />,
        },
        {
          path: '/cli-setup',
          element: (
            <GuardedRoute requiredRole="superadmin">
              <CLISetupRoute />
            </GuardedRoute>
          ),
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
