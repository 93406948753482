/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  buildResponseSchema,
  sortable,
} from '../../openapi';
import { OPENAPI_NODE_ID, OPENAPI_TENANT_ID, TYPE_UUID } from '../../types';

export const NodeHallmarkSchema = z
  .object({
    tenantID: OPENAPI_TENANT_ID,
    nodeID: OPENAPI_NODE_ID,
    mimicID: z.string(),
    mimicDescription: z.string(),
    occurrences: z.number().int(),
    firstSeen: z.string().datetime(),
    lastSeen: z.string().datetime(),
  })
  .openapi('NodeHallmark');

export const NodeHallmarkFilterParamsSchema = z
  .object({
    mimicID: z.string().optional(),
    mimicDescription: z.string().optional(),
  })
  .openapi('NodeHallmarkFilters');

export const sort = sortable('NodeHallmarkSortableFields', []);

export const NodeHallmarksQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: NodeHallmarkFilterParamsSchema.optional(),
    sort: sort.optional(),
  }),
);

export const PaginatedNodeHallmarksResponseSchema =
  buildPaginatedResponseSchema(
    'PaginatedNodeHallmarks',
    NodeHallmarkSchema,
    NodeHallmarkFilterParamsSchema,
    sort,
  );

export const ClearHallmarksSchema = z
  .object({
    reason: z.string().openapi({ example: 'Clearing hallmarks for testing' }),
  })
  .openapi('ClearHallmarks');

export const ClearHallmarksResponse = z
  .object({
    nodeId: OPENAPI_NODE_ID,
    clearEventId: TYPE_UUID,
    eventTime: z.string().datetime(),
    hallmarkCount: z.number().int(),
    deflectionCount: z.number().int(),
  })
  .openapi('ClearHallmarksResponse');

export const ClearHallmarksResponseSchema = buildResponseSchema(
  'ClearHallmarksResponseSchema',
  ClearHallmarksResponse,
);
