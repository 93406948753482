/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient } from '@tanstack/react-query';

import { Job, JobFilters, JobSortableFields } from '@/client';
import { ContentContainer } from '@/components/ContentContainer';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { defineTypes } from '@/utils/filters';
import { JobList } from '@/v1/components/JobList/JobList';
import { useGetJobsQuery } from '@/v1/utils/hooks/useGetJobsQuery';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

const JobFilterTypes = defineTypes<JobFilters>({
  name: 'string',
  targetConfig: 'string',
  operatingSystem: 'string',
  notificationDismissed: 'boolean',
  nodeConfigId: 'string',
  status: 'string',
});

export function JobsRoute() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const queryClient = useQueryClient();
  const { filters, sort, paginationParams, updateQueryParams } = useQueryParams<Job, JobFilters, JobSortableFields>(
    JobFilterTypes,
  );

  const jobsQuery = useGetJobsQuery({
    tenantId: tenantID!,
    filters,
    sort,
    settings: paginationParams,
  });

  useSubscribeToEvents(
    ['node:operational-state-changed', 'node-config:assigned', 'job:updated', 'job:completed', 'job:timedout'],
    () => {
      queryClient.invalidateQueries({ queryKey: ['jobs'] });
    },
  );

  if (!tenantID) return null;

  return (
    <ContentContainer width="100%">
      <JobList tenantID={tenantID} jobsQuery={jobsQuery} updateQueryParams={updateQueryParams} jobFilters={filters} />
    </ContentContainer>
  );
}
